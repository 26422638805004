import React from "react";
import hubspot from '../assets/images/client/hubspot.png';
import salesforce from '../assets/images/client/salesforce.png';
import gong from '../assets/images/client/gong.png';
import chorus from '../assets/images/client/chorus.png';
import dialpad from '../assets/images/client/dialpad.png';
import ringcentral from '../assets/images/client/apollo.png';

export default function CompanyLogo(){
    return(
        <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px] items-center">
            <div className="mx-auto py-4">
                <img src={hubspot} className="h-10 w-auto" alt="HubSpot Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={salesforce} className="h-10 w-auto" alt="Salesforce Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={gong} className="h-10 w-auto" alt="Gong Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={chorus} className="h-10 w-auto" alt="Chorus Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={dialpad} className="h-10 w-auto" alt="Dialpad Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={ringcentral} className="h-10 w-auto" alt="RingCentral Logo" />
            </div>
        </div>
    )
}
