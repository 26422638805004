import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import BannerImage from '../../assets/images/saas/light-dash.png'
import About1 from '../../assets/images/saas/1.png'
import About2 from '../../assets/images/saas/2.png'
import { CALENDLY_URL } from '../../constants';

import { TypeAnimation } from 'react-type-animation';

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CompanyLogo from '../../component/companyLogo';
import CookieModal from '../../component/cookieModal';

import {BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { accordionData } from '../../data/dataTwo';

export default function IndexAi(){
    const [activeIndex, setActiveIndex] = useState(0);
   
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    useEffect(() => {
        const htmlTag = document.getElementsByTagName("html")[0]
        htmlTag.classList.add('dark');
        htmlTag.classList.remove('light')

    });
    return(
        <>
         <span className="fixed blur-[200px] lg:w-[600px] w-[400px] lg:h-[600px] h-[400px] rounded-full top-[10%] md:start-[10%] -start-[20%] bg-indigo-600/20"></span>
        <span className="fixed blur-[200px] lg:w-[600px] w-[400px] lg:h-[600px] h-[400px] rounded-full bottom-[10%] md:end-[10%] -end-[20%] bg-red-600/20"></span>
        <Navbar/>
          <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
            <div className="absolute inset-0 bg-[url('../../assets/images/overlay.png')] bg-repeat opacity-10 dark:opacity-60"></div>
            <div className="container relative z-1">
                <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div className="lg:col-span-7">
                        <div className="lg:me-6 lg:text-start text-center">
                            <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">Access powerful AI <br/>For 
                            <TypeAnimation
                            sequence={[
                                ' AI-Powered Sales Coaching',
                                2000,
                                ' Customized Role Plays',
                                2000,
                                ' Performance Tracking & Feedback',
                                2000,
                            ]}
                            wrapper="span"
                            speed={50}
                            className="typewrite bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"
                            repeat={Infinity}
                        />
                        </h1>
                            <p className="text-lg max-w-xl lg:ms-0 mx-auto">Harness the power of AI to transform your sales team's performance and drive success with personalized coaching and actionable insights</p>
                        
                            <div className="subcribe-form mt-6 mb-3">
                            <form className="relative max-w-md mx-auto lg:ms-0">
                                <button type="button" onClick={() => window.open(CALENDLY_URL, '_blank')} className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center h-[50px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-md">
                                    Book a Demo
                                </button>
                            </form>

                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-5">
                        <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-200 dark:after:border-slate-700 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-200 dark:before:border-slate-700 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                            <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-40 after:w-[36rem] after:h-[36rem] after:bg-gradient-to-tl after:to-indigo-600/30  after:from-red-600/30 dark:after:to-indigo-600/50 dark:after:from-red-600/50 after: after:blur-[200px] after:rounded-full after:-z-1">
                                <img src={BannerImage} className="lg:max-w-none lg:ms-14" alt=""/>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
          </section>

          <section className="pt-6 flex flex-col items-center justify-center">
          <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="lg:w-3/4 md:w-full relative -mt-16">
                            <div className="p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
                                <div className="md:flex justify-center">
                                    <div className="md:w-1/3">
                                        <div className="text-center">
                                            <h6 className="text-slate-400 mb-0">Monthly SDR Opportunity Increase</h6>
                                            <h2 className="mb-0 text-4xl mt-3 font-bold">2+</h2>
                                        </div>
                                    </div>

                                    <div className="md:w-1/3 mt-8 md:mt-0">
                                        <div className="text-center">
                                            <h6 className="text-slate-400 mb-0">Daily Manager Time Saved</h6>
                                            <h2 className="mb-0 text-4xl mt-3 font-bold">2 hrs+</h2>
                                        </div>
                                    </div>

                                    <div className="md:w-1/3 mt-8 md:mt-0">
                                        <div className="text-center">
                                            <h6 className="text-slate-400 mb-0">Conversion Rate Increase</h6>
                                            <h2 className="mb-0 text-4xl mt-3 font-bold">132 %</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          <p className="text-sm text-slate-400 mt-4">Integrate with</p>
            <div className="container relative">
                <CompanyLogo/>
            </div>
            <p className="text-sm text-slate-400 mt-4">and 1000+ others</p>
        </section>
        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-3 grid-cols-1 shadow shadow-gray-300 dark:shadow-gray-500 rounded-lg md:divide-x-[1px] divide-y-[1px] divide-gray-200 dark:divide-gray-700">
                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-account-search-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Real-Time AI Coaching for Instant Improvement</h5>

                        <p className="text-slate-400">Transform Your Sales Skills in the Moment! With Maraca's real-time AI coaching, get instant feedback during live calls. Perfect your pitch, handle objections like a pro, and close deals with confidence. Experience the future of sales training today!</p>

                        <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1"/>
                    </div>

                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-wallet-bifold-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Tailored Training That Grows With You</h5>

                        <p className="text-slate-400">Custom Role Plays Designed Just for You. Maraca's AI analyzes your calls, identifying personal strengths and areas for growth. Enjoy daily, personalized role plays that adapt to your unique coaching needs, ensuring you're always one step ahead</p>

                        <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1"/>
                    </div>

                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-home-plus-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Seamless Integration, Unlimited Potential</h5>

                        <p className="text-slate-400">Make Your Data Work for You! Maraca integrates with your CRM, Gong, Chorus, Salesforce, and more, turning raw data into actionable insights. Prepare for sales calls like never before, with custom role plays based on upcoming meetings and performance history. Elevate your sales game to new heights with Maraca.</p>
                    </div>
                </div>
            </div>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="relative overflow-hidden rounded-lg border border-indigo-600/40 bg-indigo-600/10 dark:bg-indigo-600/20 lg:ps-12 ps-8 lg:pt-12 pt-8 lg:me-8">
                        <img src={About1} className="ltr:rounded-tl-lg rtl:rounded-tr-lg" alt=""/>
                    </div>

                    <div className="">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Adaptive Feedback System<br/> Tailored to Any Sales Methodology </h3>
                        <p className="text-slate-400 max-w-xl">Maraca's feedback system stands out for its adaptability to any sales methodology preferred by your team. Whether it's SPICED, BANT, Challenger Sale, or any other framework, our platform provides precise and actionable feedback tailored to the specific methodologies used by your team. This grading is consistently tracked and leveraged to customize lessons, ensuring a highly relevant and effective coaching experience.</p>

                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Customizable Grading Metrics</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Progress Monitoring Dashboard</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Bespoke Learning Journey</li>
                        </ul>

                        <div className="mt-4">
                        <a href={CALENDLY_URL} target="_blank" rel="noopener noreferrer" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Book a Demo <MdKeyboardArrowRight className='ms-1 text-lg'/></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="relative order-1 md:order-2">
                        <div className="relative overflow-hidden rounded-lg border border-indigo-600/40 bg-indigo-600/10 dark:bg-indigo-600/20 lg:pe-12 pe-8 lg:pt-12 pt-8 lg:ms-8">
                            <img src={About2} className="ltr:rounded-tr-lg rtl:rounded-tl-lg" alt=""/>
                        </div>
                    </div>

                    <div className="order-2 md:order-1">
                        <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Custom Scenario Creation<br/>for Targeted Practice</h4>
                        <p className="text-slate-400">Maraca's feature of creating custom scenarios allows sales teams to simulate specific sales situations, providing a practice environment that mirrors actual customer interactions. Salespeople can input details about the customer, company, objectives of the call, and the products or services being sold to initiate a realistic and relevant conversation with the AI.</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Targeted Preparation for Real Interactions</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Adaptability to Sales Dynamics</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Engagement and Retention</li>
                        </ul>

                        <div className="mt-4">
                        <a href={CALENDLY_URL} target="_blank" rel="noopener noreferrer" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Book a Demo <MdKeyboardArrowRight className='ms-1 text-lg'/></a>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
                    <div className="lg:col-span-8 md:col-span-12">
                        <div className="grid grid-cols-1 pb-8">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Transform Your Sales with Maraca: AI-Powered Mastery</h3>
        
                            <p className="text-slate-400 max-w-xl">Experience unmatched sales growth with Maraca's AI coach—personalized training, real-time feedback, and seamless integration with your sales tools. Elevate your game today!</p>
                        </div>
                    
                        <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                               
                                <span className="mdi mdi-flip-horizontal text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
        
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Personalized Role Plays Every Day</Link>
                                    <p className="text-slate-400 mt-3">Tailored scenarios that adapt to your evolving sales skills.</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-email-edit-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
        
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Data-Driven Sales Improvement</Link>
                                    <p className="text-slate-400 mt-3">Integrates with your tools to turn data into actionable insights.</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-star-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Onboarding Customized to Your Needs</Link>
                                    <p className="text-slate-400 mt-3">Platform personalized with your company and industry specifics.</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-bookmark-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">CRM and Calendar Integration</Link>
                                    <p className="text-slate-400 mt-3">Prepare for sales calls with AI-customized role plays.</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-12">
                        <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-[30px]">
                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-account-check-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Continuous Learning from Real Calls</Link>
                                    <p className="text-slate-400 mt-3">Learn from your calls with AI analysis and targeted coaching.</p>
{/*                                     
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-comment-multiple-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">AI-Powered Feedback in Real-Time</Link>
                                    <p className="text-slate-400 mt-3">Instantly refine your sales techniques with AI-generated feedback.</p>
                                    
                                    {/* <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
                    <div className="lg:col-span-4 md:mb-0 mb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions</h3>

                        <p className="text-slate-400 max-w-xl mx-auto mb-6">Top Questions from Prospects</p>

                        <a href={CALENDLY_URL} className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-gray-100 dark:border-gray-800 hover:border-indigo-600 dark:hover:border-indigo-600 text-slate-900 dark:text-white hover:text-white rounded-full" target="_blank" rel="noopener noreferrer">Book a Demo</a>
                    </div>

                    <div className="lg:col-span-8 md:mt-0 mt-8" id="accordion-collapse">
                        {accordionData.slice(0,6).map((item, index) => (
                            <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                    <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} >
                                        <span>{item.title}</span>
                                        <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </h2>
                                {activeIndex === index && (
                                    <div>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </section>
          <Footer/>
          <CookieModal/>
        </>
    )
}